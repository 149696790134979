const routes = require("next-routes")();

module.exports = routes;

routes  
  .add({ name: "articlePost", pattern: "/article/:id", page: "post" })
  .add({ name: "subscribeUs", pattern: "/subscribe-us", page: "subscribeUs" })
  .add({ name: "subscribeNoti", pattern: "/subscribe-noti", page: "subscribeNoti" })
  .add({ name: "advertiseWithUs", pattern: "/advertising", page: "sales" })
  .add({ name: "oldRouteAdvertiseWithUs", pattern: "/advertise-with-us", page: "advertiseWithUs" })
  .add({ name: "authorProfile", pattern: "/authors/:id", page: "authorProfile" })
  .add({ name: "galleryWidget", pattern: "/widgets/galleries/:id/:type", page: "galleryWidget" })
  .add({ name: "eventSummary", pattern: "/events", page: "eventSummary" })
  .add({ name: "brandTvDetail", pattern: "/sites/:brand/tv/:id", page: "videoDetail" })
  .add({ name: "brandVideoDetail", pattern: "/sites/:brand/videos/:id", page: "videoDetail" })
  .add({ name: "eventDetail", pattern: "/events/:id([\u0000-\u007F\u1000-\u109F]+)", page: "eventDetail" })
  .add({ name: "emptyTag", pattern: "/tags/:tag([\u0000-\u007F\u1000-\u109F]+)/:id", page: "error" })
  .add({ name: "categoryVideos", pattern: "/videos/channel/:id", page: "categoryVideos" })
  .add({ name: "categoryTv", pattern: "/tv/channel/:id", page: "categoryVideos" })
  .add({ name: "sectionPlaylistWidget", pattern: "/widgets/sectionVideoPlaylists", page: "sectionVideoPlaylistWidget" })
  .add({ name: "sectionVideoPlaylistWidget", pattern: "/widgets/sectionVideoPlaylists/:videoId", page: "sectionVideoPlaylistWidget" })
  .add({ name: "playlistVideoWidget", pattern: "/widgets/playlists/:id", page: "playlistWidget" })
  .add({ name: "playlistWidget", pattern: "/widgets/playlists/:id/:videoId", page: "playlistWidget" })
 
  .add({ name: "videoWidget", pattern: "/widgets/videos/:id", page: "videoWidget" })
  .add({ name: "unknownWidget", pattern: "/widgets/:id", page: "error" })
  .add({ name: "emptyWidget", pattern: "/widgets", page: "error" })
  .add({ name: "tv", pattern: "/tv", page: "videos" })
  .add({ name: "tvDetail", pattern: "/tv/:id", page: "videoDetail" })

  .add({ name: "tagArticles", pattern: "/articles/tags/:tagArticles", page: "tagArticles" })
  .add({ name: "tagArticlesUnicode", pattern: "/articles/tags/:tagArticles([\u0000-\u007F\u1000-\u109F]+)", page: "tagArticles" })

  .add({ name: "tagVideos", pattern: "/videos/tags/:tag", page: "tagVideos" })
  .add({ name: "tagVideosUnicode", pattern: "/videos/tags/:tag([\u0000-\u007F\u1000-\u109F]+)", page: "tagVideos" })

  .add({ name: "tagTv", pattern: "/tv/tags/:tag", page: "tagTv" })
  .add({ name: "tagTvUnicode", pattern: "/tv/tags/:tag([\u0000-\u007F\u1000-\u109F]+)", page: "tagTv" })

  .add({ name: "clapprTest", pattern: "/clappr-test", page: "clapprTest" })
  .add({ name: "testing", pattern: "/testing", page: "testingVideo" })
  .add({ name: "videos", pattern: "/videos", page: "videos" })
  .add({ name: "videoDetail", pattern: "/videos/:id", page: "videoDetail" })
  .add({ name: "tagItems", pattern: "/topic/:tag", page: "tagItems" })
  .add({ name: "tagItemsUnicode", pattern: "/topic/:tag([\u0000-\u007F\u1000-\u109F]+)", page: "tagItems" })
  .add({ name: "oldRouteTagItems", pattern: "/tags/:tag", page: "tagItems" })
  .add({ name: "oldRouteTagItemsUnicode", pattern: "/tags/:tag([\u0000-\u007F\u1000-\u109F]+)", page: "tagItems" })
  .add({ name: "jobs", pattern: "/jobs", page: "jobs" })
  .add({ name: "brandProfile", pattern: "/sites/:brandProfile", page: "brandProfileDetail" })
  .add({
    name: "privacyPolicy",
    pattern: "/privacy-policy",
    page: "privacyPolicy"
  })
  .add({ name: "aboutUs", pattern: "/about", page: "aboutUs" })
  .add({ name: "oldRouteaboutUs", pattern: "/about-us", page: "aboutUs" })
  .add({ name: "adInnityAdPageOne", pattern: "/ad-innity-page-one", page: "adInnityAdPageOne"})
  .add({ name: "adInnityAdPageTwo", pattern: "/ad-innity-page-two", page: "adInnityAdPageTwo"})
  .add({name: '404', pattern: '/404', page: 'error'})
  .add({ name: "searchTabNameUnicode", pattern: "/search/:term([\u0000-\u007F\u1000-\u109F]+)/:page/:eleTabName/:type", page: "searchResult" })
  .add({ name: "searchTabName", pattern: "/search/:term/:page/:eleTabName/:type", page: "searchResult" })
  .add({ name: "searchPaginate", pattern: "/search/:term/:page", page: "searchResult" })
  .add({ name: "searchResults", pattern: "/search/:term", page: "searchResult" })
  .add({ name: "searchResultsWithParam", pattern: "/search-results", page: "searchResult" })
  .add({ name: "searchHome", pattern: "/search", page: "search" })
  .add({ name: "brandPost", pattern: "/sites/:brand/:category/:id", page: "post" })
  .add({ name: "brandSubPost", pattern: "/sites/:brand/:category/:subCategory/:id", page: "post" })
  .add({ name: "categoryBrand", pattern: "/sites/:brand/:category", page: "category" })

  .add({ name: "category", pattern: "/:category", page: "category" })

  .add({
    name: "subCategory",
    pattern: "/:category/:subCategory",
    page: "category"
  })
  // .add({
  //   name: "subCategoryBrand",
  //   pattern: "/sites/:brand/:category/:subCategory",
  //   page: "subCategory"
  // })
  .add({ name: "post", pattern: "/:category/:subCategory/:id", page: "post" })
  .add({
    name: "esPost",
    pattern: "/:category/:subCategory/:subSubCategory/:id",
    page: "post"
  })
  // .add({name: 'mPost', pattern: '/:category(news|entertainment|sports|sport|women|lifestyle|tech)/:id', page: 'post'})

